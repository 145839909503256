import AgentView from "./Agent";
import { Actions as AgentViewActions } from "../../reducers/AgentState";
import { Actions as CustomerActions } from "../../reducers/CustomerState";
import { Actions as DateCenterActions } from "../../reducers/DataCenterState";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "../../hooks/withRouter";

const mapStateToProps = (state) => ({
  needSelectRemarketingTag: state.reasonView.needSelectRemarketingTag,
  selectedContact: state.customerArea.selectedContact,
  currentTab: state.agentView.currentTab,
  authUser: state.dataCenter.authUser,
});

const mapDispatchToProps = (dispatch) => ({
  changeTab: bindActionCreators(AgentViewActions.changeTab, dispatch),

  setSearchField: bindActionCreators(CustomerActions.setSearchField, dispatch),
  setSearchLeadId: bindActionCreators(
    CustomerActions.setSearchLeadId,
    dispatch
  ),
  setSearchInbound: bindActionCreators(
    CustomerActions.setSearchInbound,
    dispatch
  ),
  searchContactList: bindActionCreators(
    CustomerActions.searchContactList,
    dispatch
  ),
  setAuthUser: bindActionCreators(DateCenterActions.setAuthUser, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(AgentView)));
