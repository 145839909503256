import "./index.css";

import * as BackendApi from "./components/BackendApi";
import * as Utils from "./utils";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import moment, { customizeMoment } from "./i18n/moment";

import AgentView from "./components/Agent/Agent.Container";
import Playground from "./components/RecommendPlayground/Playground";
import App from "./App";
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom/client";
import i18n from "./i18n/i18n";
import reportWebVitals from "./reportWebVitals";
import store from "./store";

const attachEnvConfig = () => {
  console.log("Default Config: ", window.appConfig);
  window.appConfig = window.appConfig || {};
  window.appConfig = {
    logLevel:
      process.env.REACT_APP_FLEX_LOGLEVEL ||
      window.appConfig.logLevel ||
      "info",
    language:
      process.env.REACT_APP_LANGUAGE || window.appConfig.language || "en",
    backendServiceEndpoint:
      process.env.REACT_APP_SERVICE_ENDPOINT ||
      window.appConfig.backendServiceEndpoint,
    backendServiceCode:
      process.env.REACT_APP_SERVICE_CODE || window.appConfig.backendServiceCode,
    backendServiceBrand:
      process.env.REACT_APP_SERVICE_BRAND ||
      window.appConfig.backendServiceBrand,
    backendServiceLogo:
      process.env.REACT_APP_SERVICE_LOGO || window.appConfig.backendServiceLogo,
    crmAccessTokenName:
      process.env.REACT_APP_API_ACCESS_TOKEN_NAME ||
      window.appConfig.crmAccessTokenName,
  };
  console.log("Attach Env Config", window.appConfig);

  let initLang = Utils.getLanguageShort();
  moment.updateLocale(initLang, customizeMoment(initLang));
  console.log("Change Moment locale:", initLang);
  i18n.changeLanguage(initLang);
  console.log("Change i18n lang:", initLang);
  let serviceEndpoint = Utils.getServiceEndpoint();
  let serviceCode = Utils.getServiceCode();
  BackendApi.changeBackendConfig(serviceEndpoint, serviceCode);
  console.log("Change BackendApi config:", serviceEndpoint, serviceCode);
  BackendApi.check_service_code(window.appConfig.backendServiceCode).then(
    (result) => {
      if (result && result.success && result.data) {
        window.appConfig.backendServiceCode = result.data;
        serviceCode = Utils.getServiceCode();
        BackendApi.changeBackendConfig(serviceEndpoint, serviceCode);
        console.log("Change BackendApi config:", serviceEndpoint, serviceCode);
      }
    }
  );
};

attachEnvConfig();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.Fragment>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/agent" element={<AgentView key="main-component" />} />
          <Route
            path="/playground/:storeid"
            element={<Playground key="recommend-playground" />}
          />
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
