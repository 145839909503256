import * as BackendApi from '../BackendApi';

import Paper from '@mui/material/Paper';
import Slider from '@mui/material/Slider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { withStyles } from "@mui/styles";
import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "../../hooks/withRouter";
import moment from '../../i18n/moment';
import CommonLoading from '../Common/BackdropLoading';
import CommonConfirm from '../Common/ConfirmDialog';
import CommonMessage from '../Common/MessageDialog';
import CommonTips from '../Common/TipsSnackBar';
import LoginView from '../LoginView/LoginView.Container';
import { supervistaAgentTheme } from './../../theme/supervistaTheme';

import Tooltip from '@mui/material/Tooltip';

const range = (stop) => Array.from({ length: stop }, (_, i) => i);

class Playground extends Component {
  state = {
    loading: false,
    error: null,
    result: null,

    expandStaff: false,
    expandRemte: false,
    expandRemteWorker: false,
  }

  componentDidMount = async () => {
    console.log(this.props)
    const { router } = this.props
    console.log('Playground componentDidMount', router.params.storeid)
    if (router?.params?.storeid && this.props.authUser) {
      await this.loadData(router.params.storeid, router?.searchParams?.get('date'))
    }
  }

  componentDidUpdate = async (prevProps, prevState) => {
    console.log('Playground componentDidUpdate', prevProps, this.props)
    if (!prevProps.authUser && this.props.authUser && this.props?.router?.params?.storeid) {
      await this.loadData(this.props.router.params.storeid, this.props?.router?.searchParams?.get('date'))
    }
  }

  loadData = async (storeid, day) => {
    this.setState({ loading: true })
    try {
      let result = await BackendApi.recommend_playground(storeid, day)
      console.log("Result:", result)
      if (result.rule) {
        this.setState({ result: result })
      } else {
        this.setState({ result: null })
      }
    } catch (error) {
      this.setState({ result: null, error: error.code || null })
      console.log(error)
    } finally {
      this.setState({ loading: false })
    }
    return true
  }

  getWorkTimeDesc = (workTime) => workTime.map(times => times.join("-")).join(',')
  getAppListDesc = (timeList) => timeList.join(',')

  showLadder = (ladder) => {
    let masks = ladder.map(item => {
      return {
        value: item[0],
        label: `${item[1]}`,
      }
    }).reverse()
    if (masks.length === 0) {
      masks.unshift({ value: 0, label: "0" })
      masks.push({ value: 1, label: "1" })
    }
    if (masks.length > 0 && masks[0].value > 1) {
      masks.unshift({ value: 1, label: "1" })
    }
    if (masks.length > 0 && masks[0].value > 0) {
      masks.unshift({ value: 0, label: "0" })
    }

    return <Slider
      marks={masks}
      max={masks.slice(-1)[0].value}
      valueLabelDisplay="on" />
  }

  viewResult = () => {
    if (this.state.error) {
      return <>ERROR {this.state.error}</>
    }
    if (!this.state.result) {
      return <span>No data</span>
    }
    const { result } = this.state
    const { classes } = this.props
    // {this.state.result.date}
    return <>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Timezone</TableCell>
              <TableCell>Enable Remote</TableCell>
              <TableCell>Enable Staff</TableCell>
              <TableCell>Optometry Rooms</TableCell>
              <TableCell>Max App. Count</TableCell>
              <TableCell>Recommend App. Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{result.date}</TableCell>
              <TableCell>{result.timezone}</TableCell>
              <TableCell>{result.enable_remote ? 'Yes' : 'No'}</TableCell>
              <TableCell>{result.enable_staff ? 'Yes' : 'No'}</TableCell>
              <TableCell>{result.slot_app_count}</TableCell>
              <TableCell>{result.max_app_count}</TableCell>
              <TableCell>{result.recommend_app_count}</TableCell>
            </TableRow>
          </TableBody>
          <TableHead>
            <TableRow>
              <TableCell>Slot Minutes</TableCell>
              <TableCell>Day. Slots</TableCell>
              <TableCell>Last App. Expect</TableCell>
              <TableCell>App. Slots</TableCell>
              <TableCell>App. Effect Distance</TableCell>
              <TableCell>App. Effect Coefficient</TableCell>
              <TableCell>Open Time Coefficient</TableCell>
              <TableCell>Staff work time Coefficient</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{result.rule.slot_minutes}</TableCell>
              <TableCell>{result.rule.day_slots}</TableCell>
              <TableCell>{result.rule.last_app_expect}</TableCell>
              <TableCell>{result.rule.app_slots}</TableCell>
              <TableCell>{result.rule.app_effect_distance}</TableCell>
              <TableCell>{result.rule.app_effect_coefficient}</TableCell>
              <TableCell>{result.rule.open_time_coefficient}</TableCell>
              <TableCell>{result.rule.staff_work_time_coefficient}</TableCell>
            </TableRow>
          </TableBody>
          {result.remote ? <>
            <TableHead>
              <TableRow>
                <TableCell>Remote Coefficient</TableCell>
                <TableCell>Remote Score Function</TableCell>
                {result.remote.rule.remote_score_function === '_score_func_5' ?
                  <TableCell>Remote Score Ladder</TableCell>
                  :
                  <TableCell>Remote Limit Threshold</TableCell>
                }
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{result.remote.rule.free_remote_coefficient}</TableCell>
                <TableCell>{result.remote.rule.remote_score_function}</TableCell>
                {result.remote.rule.remote_score_function === '_score_func_5' ?
                  <TableCell>{this.showLadder(result.remote.rule.remote_score_ladder)}</TableCell>
                  :
                  <TableCell>{result.remote.rule.remote_limit_threshold}</TableCell>
                }
              </TableRow>
            </TableBody>
          </> : null}
        </Table>
      </TableContainer>
      <TableContainer component={Paper} sx={{ maxHeight: '80vh' }}>
        <Table stickyHeader size="small">
          <TableHead key="day-index">
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Index</TableCell>
              {range(result.rule.day_slots).map(index => <TableCell key={index}>{index}</TableCell>)}
            </TableRow>
          </TableHead>
          <TableHead key="day-time">
            <TableRow>
              <TableCell>Day</TableCell>
              <TableCell>Time</TableCell>
              {range(24).map(index => <TableCell key={index} colSpan={result.rule.hour_slots}>{moment(`${index}`, 'HH').format('HH:mm')}</TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody key="day-score">
            {!result.enable_staff ?
              <Tooltip title={`Open time Score. ${this.getWorkTimeDesc(result.open_time)}`} placement="top-start">
                <TableRow>
                  <TableCell className={classes.sticky}>Open</TableCell>
                  <TableCell className={classes.sticky2}>Score</TableCell>
                  {result.open_score.map((score, index) => <TableCell key={index}>{score >= 0 ? score : 'x'}</TableCell>)}
                </TableRow>
              </Tooltip> : null
            }
            {result.enable_staff ?
              <>
                <Tooltip title="Staff Work time Score" placement="top-start">
                  <TableRow onClick={(e) => this.setState({ expandStaff: !this.state.expandStaff })}>
                    <TableCell className={classes.sticky} rowSpan={this.state.expandStaff ? (1 + result.work_score_list.length) : 1}>Worker</TableCell>
                    <TableCell className={classes.sticky2}>Score</TableCell>
                    {result.work_score.map((score, index) => <TableCell key={index}>{score >= 0 ? score : 'x'}</TableCell>)}
                  </TableRow>
                </Tooltip>
                {this.state.expandStaff && result.work_score_list.map((work_score, i) =>
                  <Tooltip key={`work_score_${i}`} title={`Staff ${i + 1}, ${this.getWorkTimeDesc(result.staffs[i])}`} placement="top-start">
                    <TableRow key={i}>
                      <TableCell className={classes.sticky2} align='right'>{i + 1}</TableCell>
                      {work_score.map((score, index) => <TableCell key={index}>{score >= 0 ? score : 'x'}</TableCell>)}
                    </TableRow>
                  </Tooltip>
                )}
              </> : null
            }
            <Tooltip title={result.enable_staff ? "<Available Score> = <Staff work time Coefficient> * <Work Score>" : "<Available Score> = <Open Time Coefficient> * <Open Score>"} placement="top-start">
              <TableRow className={classes.topSolid}>
                <TableCell className={classes.sticky}>Available</TableCell>
                <TableCell className={classes.sticky2}>Score</TableCell>
                {result.store_score.map((score, index) => <TableCell key={index}>{score >= 0 ? score : 'x'}</TableCell>)}
              </TableRow>
            </Tooltip>
            {result.enable_remote && result.remote ?
              <>
                <Tooltip title={`<Remote Score> = <Remote Coefficient> * ${result.remote.rule.remote_score_function}(<Free Count>)`} placement="top-start">
                  <TableRow onClick={(e) => { this.setState({ expandRemte: !this.state.expandRemte }) }}>
                    <TableCell className={classes.sticky} rowSpan={this.state.expandRemte ? (this.state.expandRemteWorker ? (4 + result.remote.work_mask_list.length) : 4) : 1}>Remote</TableCell>
                    <TableCell className={classes.sticky2}>Score</TableCell>
                    {result.remote.remote_score.map((score, index) => <TableCell key={index}>{score >= 0 ? score : 'x'}</TableCell>)}
                  </TableRow>
                </Tooltip>
                {this.state.expandRemte ? <>
                  <Tooltip title="Work Remotes" placement="top-start">
                    <TableRow className={classes.topSolidRed} onClick={(e) => { this.setState({ expandRemteWorker: !this.state.expandRemteWorker }) }}>
                      <TableCell className={classes.sticky2}>Work</TableCell>
                      {result.remote.work_mask.map((score, index) => <TableCell key={index}>{score >= 0 ? score : 'x'}</TableCell>)}
                    </TableRow>
                  </Tooltip>
                  {this.state.expandRemteWorker && result.remote.work_mask_list.map((work_mask, i) =>
                    <Tooltip key={`work_${i}`} title={`Remoter ${i + 1}, ${this.getWorkTimeDesc(result.remote.optometrists[i])}`} placement="top-start">
                      <TableRow>
                        <TableCell className={classes.sticky2} align='right'>{i + 1}</TableCell>
                        {work_mask.map((score, index) => <TableCell key={index}>{score >= 0 ? score : 'x'}</TableCell>)}
                      </TableRow>
                    </Tooltip>
                  )}
                  <Tooltip title={`Remote Apps: ${this.getAppListDesc(result.remote.apps)}`} placement="top-start">
                    <TableRow>
                      <TableCell className={classes.sticky2}>Apps</TableCell>
                      {result.remote.app_mask.map((score, index) => <TableCell key={index}>{score >= 0 ? score : 'x'}</TableCell>)}
                    </TableRow>
                  </Tooltip>
                  <Tooltip title="<Free Remotes> = <Work Remotes> - <Remote Apps>" placement="top-start">
                    <TableRow className={classes.topSolidRed}>
                      <TableCell className={classes.sticky2}>Free</TableCell>
                      {result.remote.free_mask.map((score, index) => <TableCell key={index}>{score >= 0 ? score : 'x'}</TableCell>)}
                    </TableRow>
                  </Tooltip>
                </>
                  : null
                }
              </>
              : null
            }
            <Tooltip title="Date Realtime Score" placement="top-start">
              <TableRow>
                <TableCell className={classes.sticky}>Date</TableCell>
                <TableCell className={classes.sticky2}>Score</TableCell>
                {result.date_score.map((score, index) => <TableCell key={index}>{score >= 0 ? score : 'x'}</TableCell>)}
              </TableRow>
            </Tooltip>
            <Tooltip title={`Booked <App. Effect>. Apps: ${this.getAppListDesc(result.apps)}`} placement="top-start">
              <TableRow className={classes.topSolid}>
                <TableCell className={classes.sticky}>Booked App.</TableCell>
                <TableCell className={classes.sticky2}>Effect</TableCell>
                {result.app_effect_score.map((score, index) => <TableCell key={index}>{score >= 0 ? score : 'x'}</TableCell>)}
              </TableRow>
            </Tooltip>
            {result.recommend_apps.map((app, i) =>
              <React.Fragment key={`recommend_${i}`}>
                <Tooltip title={`<Available Score> ${result.remote ? '+ <Remote Score>' : ''} + <Date Realtime Score> + <Latest App. effect> * <App. Effect Coefficient>`} placement="top-start">
                  <TableRow className={`${classes.topSolid} ${classes.recommendScore}`}>
                    <TableCell className={classes.sticky} rowSpan={2}>{app.time}</TableCell>
                    <TableCell className={classes.sticky2}>Score</TableCell>
                    {app.recommend.map((score, index) => <TableCell key={index} className={index === app.index ? "recommend" : null}>{score >= 0 ? score : 'x'}</TableCell>)}
                  </TableRow>
                </Tooltip>
                <TableRow className={classes.recommendNextEfffect}>
                  <TableCell className={classes.sticky2}>Next Effect</TableCell>
                  {app.next_effect.map((score, index) =>
                    <TableCell
                      key={index}
                      className={
                        score < 0 && app.next_effect[app.index] < 0 ? (
                          index === app.index ?
                            "recommend" : (
                              index >= (app.index - result.rule.app_slots + 1) && index <= (app.index + result.rule.app_slots - 1) ?
                                'effect' : null
                            )
                        ) : null
                      }>
                      {score >= 0 ? score : 'x'}
                    </TableCell>
                  )}
                </TableRow>
              </React.Fragment>
            )}
          </TableBody>
        </Table>
      </TableContainer >
    </>
  }

  render() {
    const { classes } = this.props;
    return <ThemeProvider theme={supervistaAgentTheme}>
      <StyledEngineProvider injectFirst>
        <LoginView key="login-CRM" username={this.props.router.searchParams.get('login')} />
        <CommonConfirm key="common-confirm" />
        <CommonLoading key="common-Loading" />
        <CommonMessage key="common-message" />
        <CommonTips key="common-tips" />
        {this.props.authUser ?
          <div key="playground-recommend" className={classes.root}>
            {this.state.loading ? <span>Loading...</span> : this.viewResult()}
          </div>
          : null
        }
      </StyledEngineProvider>
    </ThemeProvider>

  }
};

const styles = theme => ({
  root: {},
  sticky: {
    position: "sticky",
    left: 0,
    backgroundColor: "#fff",
  },
  sticky2: {
    position: "sticky",
    left: '80px',
    backgroundColor: "#fff",
    borderRight: "solid",
  },
  topSolid: {
    '& td': {
      borderTop: "solid",
    },
  },
  topSolidRed: {
    '& td': {
      borderTop: "solid red",
    },
  },
  recommendScore: {
    '& .recommend': {
      backgroundColor: '#24df24',
    }
  },
  recommendNextEfffect: {
    '& .recommend': {
      backgroundColor: '#ff3838',
    },
    '& .effect': {
      backgroundColor: '#ff7189',
    },

  }
})

const mapStateToProps = (state) => ({
  authUser: state.dataCenter.authUser,
});

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(Playground)));
